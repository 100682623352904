import React from "react";
import Hero from "../../components/Hero/Hero";
import Features from "../../Containers/Features/Features";
export default function Home() {
  return (
    <main>
      <Hero></Hero>
      <Features></Features>
    </main>
  );
}
